import { Injectable, signal } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { PdfSection, TableContent } from '../entities/pdf-content';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { JourneyExcludedAdviceArea } from '../entities/journey-excluded-advice-area';
import { JourneyService } from './journey.service';
import { NameValue } from '../entities/name-value';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PdfService } from './pdf.service';
import { createGuid } from '../shared/util';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class JourneyExcludedAdviceAreaService {

  constructor(
    private http: HttpClient,
    private housholdService: HouseholdService,
    private journeyService: JourneyService
  ) { }

  journeyExcludedAdviceAreas = signal<JourneyExcludedAdviceArea[]>([]);

  getJourneyExcludedAdviceArea(): Observable<JourneyExcludedAdviceArea[]> {
    const journeyID = this.journeyService.getNonNullableJourney().journeyID;
    return this.http.get<JourneyExcludedAdviceArea[]>(`/api/journeyexcludedadviceareas/${journeyID}/latest`);
  }

  saveJourneyExcludedAdviceArea(journeyExcludedAdviceAreas: JourneyExcludedAdviceArea[]): Observable<JourneyExcludedAdviceArea[]> {
    return this.http.post<JourneyExcludedAdviceArea[]>("/api/journeyexcludedadviceareas/", journeyExcludedAdviceAreas);
  }

  async updateJourneyExcludedAdviceArea(journeyExcludedAdviceArea: JourneyExcludedAdviceArea): Promise<void> {
    this.journeyExcludedAdviceAreas
      .update(x => [...x.filter(y => y.adviceAreaID !== journeyExcludedAdviceArea.adviceAreaID), journeyExcludedAdviceArea]);
    await firstValueFrom(this.saveJourneyExcludedAdviceArea([journeyExcludedAdviceArea]));
  }

  async addJourneyExcludedAdviceArea(journeyExcludedAdviceArea: JourneyExcludedAdviceArea): Promise<void> {
    if (!journeyExcludedAdviceArea.adviceAreaID) journeyExcludedAdviceArea.adviceAreaID = createGuid();
    this.journeyExcludedAdviceAreas.update(x => [...x, journeyExcludedAdviceArea]);
    await firstValueFrom(this.saveJourneyExcludedAdviceArea([journeyExcludedAdviceArea]));
  }

  async deleteJourneyExcludedAdviceArea(journeyExcludedAdviceArea: JourneyExcludedAdviceArea): Promise<void> {
    journeyExcludedAdviceArea.isDeleted = true;
    this.journeyExcludedAdviceAreas.update(x => x.filter(y => y.adviceAreaID !== journeyExcludedAdviceArea.adviceAreaID));
    await firstValueFrom(this.saveJourneyExcludedAdviceArea([journeyExcludedAdviceArea]));
  }

  async refresh(): Promise<void> {
    this.journeyExcludedAdviceAreas.set(await firstValueFrom(this.getJourneyExcludedAdviceArea()));
  }

  createJourneyExcludedAdviceArea(): JourneyExcludedAdviceArea {
    return {
      journeyID: this.journeyService.getNonNullableJourney().journeyID,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate(),
      adviceAreaID: createGuid(),
      isDeleted: false
    };
  }

  getPdfSection(): PdfSection {
    const pdfSection: PdfSection = {
      breakLine: false,
      pdfSectionType: PdfSectionTypes.Table,
      title: "Excluded areas of advice",
      content: {
        tableHeaders: [
          {
            name: "Advice topic",
            width: "30%"
          },
          {
            name: "Reason this is not included and impact of this",
            width: "60%"
          }
        ],
        tableRows: []
      }
    };

    for (const item of this.journeyExcludedAdviceAreas()) {
      const nameValues = JourneyExcludedAdviceAreaService.getJourneyExcludedAdviceAreaFields(item);

      (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x)));
    }

    return pdfSection;
  }

  private static getJourneyExcludedAdviceAreaFields(journeyExcludedAdviceArea: JourneyExcludedAdviceArea): NameValue[] {
    return [
      {
        name: journeyExcludedAdviceArea.category ?? "-",
        value: journeyExcludedAdviceArea.comment,
      }
    ];
  }
}